.logosWrap {
  display: flex;
  justify-content: flex-start;
  height: 30px;
  margin-top: 6px;

  img {
    height: 35px;
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }
  }
}