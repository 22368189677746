@import 'shared-styles/mixins';

.root {
  padding: 12px 23px 17px;
  margin-bottom: 15px;

  &UseEnlargedFont {
    input {
      font-size: 1.6rem !important;
    }
  }
}

.cvcAndExpiryWrap {
  display: flex;
  &IsTightFit {
    display: block;
  }
}

.expiryWrap {
  display: flex;
  align-items: flex-end;
}

.expirySlash {
  padding: 0 15px;
}

.expiryYear, .expiryMonth {
  width: 50px;
  &IsTightFit {
    width: 100%;
  }
}

.cvc {
  width: 150px;
  &IsTightFit {
    width: 100%;
  }
}

.cvcWrap {
  position: relative;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.formRow {
  margin-bottom: 7px;
  input {
    @include inputAutoFill;
  }
  &IsDark {
    input {
      color: white;
      &::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.4);
      }
      &:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.4);
      }
      &::placeholder {
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }
}