
.heading {
  margin-bottom: 15px;
}

.divider {
  margin-bottom: 15px;
}


.paperBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 25px 30px 25px;
}

.group {
  margin-top: 35px;
  &:first-child {
    margin-top: 0;
  }
}