.root {
  padding: 5px;
  width: 100%;
}

.timeCell {
  position: absolute;
}

.leftTimeCellIcons {
  position: absolute;
  top:  10px;
  left: 2px;
}


.rightTimeCellIcons {
  position: absolute;
  top:  10px;
  right: 2px;
}

