.root {
  padding: 15px;
}

.cellValue {
  color: black;
  height: 40px;
  position: relative;
}

.venueNameCell {
  min-height: 50px;
  margin-top: 10px;
}

.venueName {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.gridCell {
  border-right: 1px solid rgba(169, 169, 169, 0.5);
  border-bottom: 1px solid rgba(197, 197, 197, 0.5);
  margin-right: -1px;
  padding-top: 10px;
}

.servicesCell {
  font-weight: bold;
  margin-bottom: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.venueClosed {
  position: absolute;
  right: 50%;
  margin-top: 10px;
  font-weight: bold;
  color: #e84e40;
}

