.root {
  flex-grow: 1;
  text-align: center;
  height: 100%;

  background-color: #f3f3f3 !important;
  &IsDark {
    background-color: rgba(#fff, 0.11) !important;
  }

  padding: 12px 11px 13px;
  &HasCb {
    padding: 16px 4px;
  }

  & > * {
    margin-top: 8px;
    &:first-child {
      margin-top: 2px;
    }
  }
}

.contentWrap {
  display: flex;
  justify-content: center;
}

.price {
  margin-top: 7px;
  margin-bottom: 8px;
  font-size: 2rem !important;

  &HasCb {
    margin-top: 3px;
    margin-bottom: 4px;
  }
}

.link {
  word-break: break-all;

  &HasCb {
    margin-top: 0;
    margin-bottom: 4px;
  }
}

.label,
.description {
  pre {
    white-space: pre-wrap;
  }
}

.description,
.paymentText {
  font-size: 1.3rem;
  line-height: 1.3;
}

.label {
  font-size: 1.6rem;
  line-height: 1.3;
  &HasCb {
    line-height: 1.2;
    position: relative;
  }
  &IsStacked {
    font-size: 1.5rem;
  }
}

.description {
  margin-top: 12px !important;
  min-height: 2rem;
  max-height: 105px;
  overflow-y: auto;
  display: block;

  &HasCb {
    margin-top: 0;
  }

  ul {
    text-align: left;
    padding-left: 30px;
  }

  p {
    margin: 2px 0 0;
  }
}


.checkbox {
  padding: 0;
}
