.BrainhubCarousel__container {
  width: 100%;
  overflow: hidden;
}

.BrainhubCarousel {
  overflow: hidden;
  display: flex;
  align-items: center;

  &.BrainhubCarousel--isRTL {
    direction: rtl;

    .BrainhubCarousel__trackContainer {
      .BrainhubCarousel__track {
        direction: rtl;
      }
    }
  }

  .BrainhubCarousel__trackContainer {
    overflow: hidden;
    .BrainhubCarousel__track {
      display: flex;
      overflow: hidden;
      list-style: none;
      margin: 0;
      padding: 0;

      &.BrainhubCarousel__track--transition {
        transition: transform;
      }
    }
  }
}

/* arrows */
.BrainhubCarousel__arrows {
  cursor: pointer;
}
