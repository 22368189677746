.root {
  padding: 15px;
}

.homePage {
  display: block;
}

.brandImage {
  position: absolute;
  top: 77px;
  left: 20px;
  width: 70px;
  opacity: 0.4;
  height: 65px;
}

.brandName {
  position: absolute;
  top: 77px;
  right: 20px;
  width: 125px;
  opacity: 0.3;
}

@media only screen and (max-width: 1100px) {
  .brandImage, .brandName {
    display: none;
  }
}