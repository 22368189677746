
.root {
  display: flex;
  flex-direction: column;
}

.alertWrap {
  margin-bottom: 10px;
}

.btnWrap {
  display: flex;
  justify-content: space-between;
  &IsCentered {
    justify-content: center;
  }
  &IsStacked {
    padding-top: 7px;
  }
}

.disabledNext {
  opacity: 0;
  position: absolute;
  box-shadow: none;
  right: 0;
}

.nextWrap {
  position: relative;
}


.navBtn {
  line-height: 1.3;
  padding: 8px 15px;
  font-size: 1.5rem;
  margin: 8px;
  
  &IsChunky {
    font-size: 1.6rem;
  }
}

.nextLoader {
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  margin-left: -10px;
  margin-top: -10px;
}

.nextText {
  &IsLoading {
    opacity: 0.4;
  }
}