.root {
  padding: 5px 0;

  &IsStacked {
    padding: 5px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;

  &IsStacked {
    flex-direction: column;

    & > * {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
  }

  &NoCarouselShown {
    margin-top: 10px;
  }
}

.listItemsWrap {
  width: 100%;
  padding: 5px;
}

.grid {
  justify-content: center;
}

.gridItem {
  &IsStacked {
    padding: 4px !important;
  }
}

.listItem {
  position: relative;
  flex-grow: 1; /* for flex version */
  display: flex;
  flex-direction: column;
  height: 100%; /* for grid version */

  &IsStacked {
    padding: 4px;
  }

  &IsNarrow {
    padding: 0;
  }
}

.listItemCheckbox {
  position: absolute;
  top: 0;
  left: 8px;
}

.optionsText {
  margin-bottom: 8px !important;
  font-size: 1.7rem !important;;
  text-align: center !important;;
}

.dots {
  margin-top: 20px;
  margin-bottom: 5px;

  :global {
    .BrainhubCarousel__dot {
      background-color: transparent;
      &:before {
        background: #000 !important;
        width: 9px !important;
        height: 9px !important;
      }
    }
  }

  &IsDark {
    :global {
      .BrainhubCarousel__dot {
        &:before {
          background: #e0e0e0 !important;
        }
      }
    }
  }
}

.singleList {
  padding-left: 5px;
  padding-right: 5px;
  &IsStacked {
    padding-left: 0;
    padding-right: 0;
  }
}

.carouselNavBtns {
  & > * {
    margin: 0 5px;
  }
  &IsStacked {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 10px;
  }
}

.box {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;

  .boxItems {
    padding: 8px;
    min-width: 25%;
    max-width: 25%;
    display: flex;
    flex-direction: column;
  }
}

