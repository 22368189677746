
.box {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;

  .boxItems {
    padding: 8px;
    min-width: 25%;
    max-width: 25%;
    display: flex;
    flex-direction: column;
  }
}
