:global {
  @import '../../global-styles/react-carousel/Carousel.scss';
  @import '../../global-styles/react-carousel/CarouselItem.scss';
  @import '../../global-styles/react-carousel/CarouselDots.scss';
  @import '../../global-styles/react-carousel/Arrows.scss';
}

html, body {
  width: 100%;
  height: 100%;
  background-color: rgb(245, 245, 245);
  margin: 0;
  font-size: 62.5%;
  font-family: "Roboto Condensed", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  scroll-behavior: smooth;
  background-image: url('/images/BrandImageBackground.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: contain;
  background-position: 50% 0;
}

.newApp {
  height: auto;
  min-height: 100%;
}
