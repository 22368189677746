.root {
  display: flex;
  justify-content: center;
  margin-top: 13px;
}

.extrasBtnText {
  flex-grow: 1;
  line-height: 1.2;
  font-size: 1.3rem;
}

.table {
  border: none !important;
  td {
    border: none !important;
    padding: 3px 0 3px 0;
    line-height: 1.2;
    font-size: 1.1rem;
    &:last-child {
      text-align: right;
      padding-right: 0;
    }
  }
  tr {
    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}

.editIconBtn {
  padding: 6px 4px;
  svg {
    font-size: 1.8rem;
  }
}

.addIcon {
  font-size: 1.8rem;
}
