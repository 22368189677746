.root {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(white, 0.9);
  &IsDark {
    background-color: rgba(black, 0.8);
  }
}

.loader {
  width: 100%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.message {
  margin-top: 15px;
  &IsDark {
    color: white;
  }
}