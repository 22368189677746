

.paper {
  margin-left: 20px;
  margin-right: 20px;
  padding: 15px;
  width: 100%;

  &IsStacked {
    max-height: calc(100% - 16px);
    margin-left: 8px;
    margin-right: 8px;
    padding: 12px 4px;
    width: 100%;
  }
}

.btn {
  line-height: 1.4;
}

.btnWrap {
  padding: 15px 0 0 0;
  justify-content: space-between;

  &IsStacked {
    padding: 15px 8px 0;
    justify-content: space-between;
  }
}

.title {
  padding: 5px 8px 5px;

  &IsStacked {
    text-align: center;
  }
}

.content {
  padding: 8px 5px;
  box-sizing: border-box;

  & * {
    box-sizing: border-box;
  }
}

.contentInner {
  transition: max-height 1s ease-in-out;

  max-height: 800px;

  &IsStacked {
    max-height: none;
  }

  min-height: 150px;
  /* overflow: hidden; */
  &.isLoading {
    max-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.explicitWrap {
  padding-top: 20px;

  &IsStacked {
    padding-top: 0;
  }
}

.implicitWrap {
  padding-top: 12px;
  padding-bottom: 10px;

  &IsStacked {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.implicitHeader {
  margin-bottom: 12px;
  font-size: 2rem;
  text-align: center;
}

.closeButton {
  position: absolute;
  right: 1px;
  top: 1px;
  color: gray;
}

.buttonRight{
  justify-content: flex-end;
}

.box {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;

  .boxItems {
    padding: 8px;
    min-width: 25%;
    max-width: 25%;
  }
}

