.root {
  padding: 15px;
}

.timeHeader {
  text-align: left;
  padding: 8px;
}

.headerCell{
  padding: 10px 0;
}

.gridHeader{
  color: white;
}