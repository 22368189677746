.tableWrap {
  margin: 20px 0 5px;
}

.buttonWrapStyles {
  display: flex;
  justify-content: space-between;
}

.buttonWrapStylesSingleButton {
  display: flex;
  justify-content: flex-end;
}

.bookingOptHeading {
  font-size: 15px;
  margin: 25px 7px 6px;
}

.errorMessage {
  margin-top: 25px;
}

.paymentMessage {
  margin-top: 10px;
}