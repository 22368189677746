
.root {
  display: flex;
  position: relative;
  flex-direction: row;
}

.toolbarBtn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 10;
  outline: none !important;
}

.calenderWrap {
  position: relative;
}

.calender {
  margin: auto;
  padding: 15px;
  width: 150px;
}


.errorMsg {
  padding: 10px;
  p {
    margin: 0;
  }
  color: #e84e40;
}
