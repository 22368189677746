.root {
  padding: 15px;
}


.paper {
  margin-left: 20px;
  margin-right: 20px;
  padding: 15px;
}

.btn {
  line-height: 1.4;
}

.btnWrap {
  padding: 15px 0 0 0;
}

.title {
  padding: 10px 8px 15px;
}

.content {
  padding: 8px 5px;
}

.markdownText {
  line-height: 1.3;
  p {
    margin: 0 0 7px 0;
  }
  ul, ol {
    padding-left: 25px;
  }
}
