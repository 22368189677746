.container {
  display: block;
  &IsLandscape {
    display: flex;
  }
}

.rightColumn, .leftColumn {
  
  padding-bottom: 12px;
  &IsLandscape {
    padding-bottom: 0;
  }

  &DoGrow {
    flex-grow: 1;
  }
  &IsWide {
    width: 50%;
  }
}

/* .leftColumn { */

/*   &IsWide { */
/*     width: 50%; */
/*   } */
/* } */

.rightColumn {

  &DoGrow,
  &IsWide {
    padding-left: 15px;
  }
}