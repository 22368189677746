.root {
  padding: 30px 15px;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;

  &IsDark {
    color: white;
  }
}

.heading {
  margin-bottom: 12px;
}
.message {
  margin-bottom: 25px;
}