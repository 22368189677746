
$darkBorderClr: rgba(255, 255, 255, 0.2);
$lightBorderClr: rgba(224, 224, 224, 1);

.row {
  
  td {
    padding: 14px 6px;
    border-bottom: none;
    border-top: 1px solid $lightBorderClr;
  }

  &.tableRowHasChildren {
    td {
      padding-bottom: 4px;
    }
  }

  &.tableRowChild {
    td {
      padding: 2px 12px;
      color: rgba(0, 0, 0, 0.6);
      border-top: none;
      &:first-child {
        padding-left: 15px;
      }
    }

    &IsDark {
      td {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  &.tableRowLastChild {
    td {
      padding-bottom: 10px;
    }
  }

  &IsMbLook {

    td {
      padding-top: 6px;
      padding-bottom: 6px;
      padding-right: 16px;
      border-color: $lightBorderClr;

      &:first-child {
        padding-left: 16px;
      }
    }
    &:last-child {
      td {
        border-bottom: 1px solid $lightBorderClr;
      }
    }

    &IsDark {
      td {
        border-color: $darkBorderClr;
      }
      &:last-child {
        td {
          border-bottom: 1px solid $darkBorderClr;
        }
      }
    }

    &.tableRowChild {
      td {
        padding-right: 16px;
        &:first-child {
          padding-left: 22px;
        }
      }
    }
  }
}
