.root {
  display: flex;
  flex-direction: row;
}

.prefixContainer {
  width: 125px;
  max-width: 125px;
  min-width: 125px;
  display: flex;
  align-items: flex-end;
}

.prefixPaddingContainer {
  padding-bottom: 2.7rem;
}

.prefixDiaryPaddingContainer {
  align-items: flex-start;
  padding-top: 1.9rem;
}

.phoneNumberInputContainer {
  flex: 1;
}




.prefixFlagContainer {
  padding-bottom: 0px !important;
  width: 125px;
}
.prefixButtonContainer {
  padding: 3px 15px 3px 2px !important;
  border-width: 0px !important;
  border-bottom-width: thin !important;
  border-radius: 0px !important;
  border-color: #949494 !important;
}

.prefixButtonContainer > span:first-child {
  padding: 0px 8px 0px 0px !important;
}


.darkMode > div > button > span:first-child > span:nth-child(2) {
  color: white;
}

.darkMode > div > ul, .darkMode > div> ul > div, .darkMode > div> ul > div > input {
  background: #858585;
}

.darkMode > div> ul > div > input {
  color: white;
  border-width: 1px;
  border-style: solid;
  border-color: white;
}

.darkMode > div> ul > div > input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.7;
}

.darkMode > div> ul > div > input:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.7;
}

.darkMode > div> ul > div > input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.7;
}

.darkMode > div> ul > div > input:-ms-input-placeholder:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white;
  opacity: 0.7;
}

.darkMode > div> ul > div > input::placeholder:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white;
  opacity: 0.7;
}

.darkMode > div> ul > div > input:-ms-input-placeholder::-ms-input-placeholder { /* Microsoft Edge */
  color: white;
  opacity: 0.7;
}

.darkMode > div> ul > div > input::placeholder::-ms-input-placeholder { /* Microsoft Edge */
  color: white;
  opacity: 0.7;
}

.isDiary > div > div > p {
  font-size: 12px;
}

.isDiary > div > div > div > input {
  padding: 7px 0px !important;
}

.isDiary > div > div > div > input:hover {
  outline: none !important;
}

.isDiary > div > div > div > input:focus {
  outline: none !important;
}

.isDiary > div > div > div::before {
  border-color: rgba(0,0,0,0.12);
}

.isDiary > div > div > div:hover::before {
  border-width: 1px !important;
  border-color: rgba(0,0,0,0.12) !important;
}

.isDiary > div > div > div[class~="Mui-error"]:hover::before {
  border-color: transparent !important;
}

.isDiary > div > div > div[class~="Mui-focused"]:hover::before {
  border-color: transparent !important;
}

.isDiary > div > div > div::after {
  border-bottom-width: 1px !important;
}

.isDiary > div > div > div[class~="Mui-focused"]::after {
  border-bottom-width: 2px !important;
}

.isDiaryPrefixButtonContainer {
  border-color: rgba(0,0,0,0.12) !important;
}
