.root {
  padding: 20px 25px 25px;
}

.heading {
  margin-bottom: 15px;
}

.divider {
  margin-bottom: 15px;

  &IsDark {
    background-color: #e0e0e0;
  }
}

.paymentMessage {
  margin-bottom: 17px;
}
.tableWrap {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); /* same color as divider */
  padding-top: 3px;

  &IsDark {
    border-bottom-color: #e0e0e0;
  }

  tr {
    
    td {
      padding: 14px 6px;
      border-bottom: none;
      border-top: 1px solid rgba(224, 224, 224, 1);
    }
  }
}
.cardLogos {
  margin-top: 20px;
}

.alert {
  margin-top: 20px;
}