
.radGrp {
  display: block;

  &IsStacked {
    text-align: center;
  }
}

.radBtn {
  padding: 3px 5px;
}

.radLbl {
  margin-right: 18px;
  word-break: break-word;

  &IsStacked {
    display: inline-block;
  }
}

.message {
  margin-bottom: 10px;
}

.radioWrap {

  &IsStacked {
    width: 100%;
  }
}