@import 'shared-styles/mixins';

.root {
  padding: 20px 23px;
  margin-bottom: 15px;
}

.cvcWrap,
.cardNameWrap,
.expiryWrap,
.cardNumberWrap {
  display: block;
  margin: 0 0 20px 0;
  color: rgba(0, 0, 0, 0.75);
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 7px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.cvcWrap {
  min-width: 150px;
  position: relative;
}

.cardNameWrap {

  input {
    display: block;
    border: none;
    outline: none;
    width: 100%;
    padding: 2px 0 0 0;
    font-size: 16px; /* must match stripe styles (which are not in rems) */
    font-weight: 400;
    font-family: inherit;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.87);
    &::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.4); /* must match stripe styles */
    }
    &:-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.4); /* must match stripe styles */
    }
    &::placeholder {
      color: rgba(0, 0, 0, 0.4); /* must match stripe styles */
    }

    @include inputAutoFill;
  }

  &IsDark {
    input {
      color: white;
      &::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.4);
      }
      &:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.4);
      }
      &::placeholder {
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }
}

.fieldLabel {
  margin-bottom: 4px;
  display: block;
  &IsDark {
    color: white;
    border-bottom-color: #e0e0e0;
  }
}

.expiryCvcRow {
  display: flex;
  justify-content: space-between;

  &IsTightFit {
    display: block;
  }
}

.alert {
  margin-bottom: 5px;
}