
.root {
  display: flex;
  justify-content: space-between;
}


.nextBtn {
  margin-left: 8px;
}

.navBtn {
  margin-left: 8px;
  &:first-child {
    margin-left: 0;
  }
}

.navBtn, .nextBtn {
  line-height: 1.3;
  padding: 8px 15px;
  font-size: 1.5rem;

  &IsChunky {
    font-size: 1.6rem;
  }
}

.disabledNext {
  opacity: 0;
  position: absolute;
  box-shadow: none;
  right: 0;
}

.nextWrap {
  position: relative;
}

.navBtnIsTightFit,
.nextBtnIsTightFit {
  font-size: 1.4rem;
  padding-right: 9px;
  padding-left: 9px;
}