.root {
  display: block;
}

.row2 {
  display: flex;
}

.menuItemText {
  padding: 10px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}


.menuItemMid {
  max-width: calc(100% - 34px);
  width: 100%;
  display: flex;
  align-items: center;
}

.menuItemIconWrap {
  svg {
    width: 21px;
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
    margin-left: 5px;

    &.walkinIcon {
      margin-right: -6px;
      height: 21px;
      -webkit-transform: translateY(1px);
              transform: translateY(1px);
    }

    &.onlineIcon {
      height: 19px;
      -webkit-transform: translateY(1px);
              transform: translateY(1px);
      margin-right: -3px;
    }

    &.tableJoinIcon {
      -webkit-transform: rotateZ(90deg) translateX(3px);
              transform: rotateZ(90deg) translateX(3px);
      margin-left: 1px;
      margin-right: -8px;
    }
  }
}

.tableValidationMsg {
  font-size: 1.2rem !important;
  margin-top: 5px !important;
}

.sectionHeader {
  &Wrap {
    padding: 0 !important;
    display: block !important;
    opacity: 1 !important;
  }
  padding: 9px 5px 9px 10px;
  margin: 0;
  color: #898989;
  background-color: #f3f3f3; /* has to be slightly lighter than high light colour */
  pointer-events: none;
}

.footerKey {
  margin-top: 10px;
  overflow-x: auto;
  width: 100%;

  &Row {
    padding: 5px 0 6px;
    color: #898989;
    font-size: 1.2rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    min-width: 268px;

    & > * {
      padding: 0 10px 0 0;
      position: relative;
      &:last-child {
        padding-right: 0;
      }
    }
  }

  &Row1 {
    span {
      font-size: 1.5rem;
      font-weight: bold;
      display: inline-block;
      color: #546e7a;
    }
  }
  &Online {
    svg {
      height: 19px;
      -webkit-transform: translateY(4px);
              transform: translateY(4px);
    }
  }
  &Shared {
    svg {
      width: 20px;
      -webkit-transform: translateY(6px);
              transform: translateY(6px);
    }
  }
  &Covers {
    margin-left: 15px;
    span {
      font-size: 12px !important;
      position: absolute;
      left: -12px;

    }
  }
  &Walkin {
    svg {
      $w: 12px; /* must be same as viewport on svg element */
      height: 20px;
      width: $w;
      -webkit-transform: translateY(2px);
              transform: translateY(2px);
      path {
        -webkit-transform: translateX(-#{calc($w / 2)});
                transform: translateX(-#{calc($w / 2)});
      }
    }
  }
}

.noTables {
  width: 100%;
}
