
.radWrap {
  display: block;
  margin-bottom: 5px; /* just so not so close to bottom edge */
}

.radGrp {
  display: block;
}

.radBtn {
  padding: 3px 5px;
  order: 2;
  margin-top: 7px;
}

.radLbl {
  & span {
    font-size: 1.6rem;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

.validationMsg {
  margin-top: 15px;

  &IsCentered {
    text-align: center;
  }
}

.counterMessage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;

  .boxItems {
    padding: 8px;
    min-width: 25%;
    max-width: 25%;
    display: flex;
    flex-direction: column;
  }
}

