

.root {
  input {
    &:-webkit-autofill {
      &, &:hover, &:focus, &:active {

        /*
         * Hack to prevent background color looking crappy. Transition delay is so long it "never" gets seen.
         * REF: https://stackoverflow.com/questions/29580704/change-input-background-to-transparent-on-autocomplete-form
         */
        transition-delay: 9999s;
      }
    }
  }
}

.column2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
}

.paperBox {
  padding: 15px 28px 34px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.paperBox1 {
  height: auto;

  &IsLandscape {
    /* we want the left to match the height of right, but not if there is too many tags, as height can get really tall */
    height: 100%;
    max-height: 360px;
  }
}

.paperBox2 {
  margin-bottom: 15px;
  flex-grow: 1;
}

.formText {
  width: 100%;
  margin-bottom: 5px;

  /*
  iPhone's zoom into input text fields if they are less than 16px, so we device sniff here
  and give Apple what it wants.
  */
  &UseEnlargedFont {
    textarea,
    input {
      font-size: 1.6rem !important;
    }
  }
}

.terms {
  margin-bottom: 20px;

  textarea {
    font-size: 1.2rem;
  }
}

.gridItem1, .gridItem3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.centerAlignItemContents {
  align-items: center;
}

.tagsWrap {
  padding: 6px 4px;

  &IsLandscape {
    margin-left: -11px;
  }
}

.termsControl {
  margin-right: 25px;
  margin-bottom: -10px;
}

.tagControl {
  margin-bottom: 3px;
  min-width: 50%;
  margin-right: 0;
  margin-left: 0;

  &IsStacked {
    margin-left: 0;
  }

  &IsStandard {
    width: 100%;
  }
}

.specialWrap {
  width: 100%;
  margin-bottom: 3px;
}


.checkbox {
  padding-right: 7px;
}

.divider {
  margin-bottom: 5px;
  /* same colour as the borders of form fields */
  background-color: rgba(0, 0, 0, 0.42);
}

.specialDivider2 {
  margin-bottom: 11px;
}

.alert {
  margin-top: 18px;
  margin-bottom: -10px;
}

.smallLabel {
  font-size: 1.3rem;
}

.rightAlign {
  display: flex;
  justify-content: right;
}

.standbyForm {
  display: flex;
  width: 800px;
  flex-wrap: wrap;
  justify-content: space-around;
}

.standbyFormCol1 {
  width: 380px;
}

.standbyFormCol2 {
  width: 380px;
  margin-top: 0px;
}

.paperBoxCol2 {
  padding: 15px 28px 42px;
}


.birthdayBox {
  margin-top: 5px;
}

.dateBox {
  display: flex;
  flex-wrap: wrap;
}

.dayMonthList {
  flex: 1 1 auto;
  min-width: 80px;
  margin-right: 5px;
}

.yearText {
  flex: 1 1 auto;
  width: 50px;
  margin-top: 2px;
}

.birthdayLabel {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1.4rem;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  line-height: 1;
}