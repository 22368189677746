.root {
  padding: 5px;
}

.grid {
  justify-content: center;
}

.listItem {
  position: relative;
  flex-grow: 1; /* for flex version */
  display: flex;
  flex-direction: column;
  height: 100%; /* for grid version */
}

.optionsText {
  margin-bottom: 8px !important;
}

.box {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;

  .boxItems {
    padding: 8px;
    min-width: 25%;
    max-width: 25%;
  }
}


.box {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;

  .boxItems {
    padding: 8px;
    min-width: 25%;
    max-width: 25%;
    display: flex;
    flex-direction: column;
  }
}

