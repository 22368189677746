.root {
  padding: 10px 0 4px;
}

.labelText {
  font-size: 0.7rem;
  margin-right: 0;
}

.alert {
  margin-top: 12px;
}