.root {
  padding: 25px 30px;
  max-width: 600px;
  margin: 20px auto 0;
}

.title {
  margin-bottom: 10px;
}

.divider {
  margin-bottom: 15px;
  /* same colour as the borders of form fields */
  background-color: rgba(0, 0, 0, 0.42);
  &IsDark {
    background-color: rgba(255, 255, 255, 0.42);
  }
}
.btnWrap {
  margin-top: 20px;

  > * {
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.body {
  p {
    margin: 0 0 6px 0;
    line-height: 1.4;
  }
}
