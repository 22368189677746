.root {
  padding: 15px;
}

.rowPosters {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 20px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.venueGridScroll {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
}

.venueCellSticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: -1px;
  align-self: start;
  z-index: 10;
  background-color: whitesmoke;
  box-shadow: 7px 0 3px -2px rgba(0, 0, 0, 0.24);
  height: 100%;
  border-bottom: 1px solid rgba(197, 197, 197, 0.5);
}

.gridBody{
  margin-top: 10px;
}

.gridRow:nth-child(odd){
  background-color: white;
}

.gridContainer{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}