$sessionBannerH: 20px;

.sessionRemaining {
  display: flex;
  border-radius: 6px;
  padding: 5px 6px;
  margin: 10px;

  &IsStacked {
    justify-content: center;
    align-items: center;
    height: $sessionBannerH;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 0;
    padding: 0;

    .hideBreak {
      display: none;
    }
  }

  &IsRounded {
    border-radius: 5px;
  }
}


.sessionRemainingText {
  font-size: 1.2rem;
  line-height: 1.3;
  text-align: center;
}
