.root {
  display: flex;
  flex-direction: column;

  width: 100%;
  &IsSmall {
    width: auto;
  }
}

.numberInput {
  width: 90px;

  input {
    text-align: center;
    font-size: 2.3rem;
    padding: 4px;


    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &IsSmall {
    width: 40px;
    margin-top: 0;
    margin-bottom: 0;

    input {
      font-size: 1.5rem;
      background-color: rgba(#fff, 0.5);
      border-radius: 5px;
      /*padding: 8px 5px 5px 5px; */
    }
  }
}

.incrementBtn {
  &IsSmall {
    width: 27px;
    height: 27px;
    min-height: 27px;
  }
}

.incrementIcon {
  &IsSmall {
    font-size: 1.9rem;
  }
}

.incrementBtns {
  display: flex;
  align-items: center;
  padding: 0 0 0 15px;

  &IsLandscape {
    flex-grow: 1;
  }
  &IsSmall {
    padding-left: 11px;
  }
}

.minusBtn {
  margin-right: 4px!important;
}

.plusBtn {
  margin-left: 4px!important;
}

.inputWrap {
  display: flex;
  width: 100%;

  justify-content: center;
  &IsLandscape {
    justify-content: flex-start;
  }
}

.message {
  margin: 3px 0 0 0;
}
