.root {
  padding-bottom: 12px;
}

.expanderHeading {
  font-size: 1.5rem;
  line-height: 1.2;
  padding: 9px 0 8px;

  text-align: center;
  margin: 0 auto;
  &IsLandscape {
    text-align: left;
    margin: 0;
  }
}


.expanderSmallText {
  font-size: 1.2rem;
}



.expPnlDet {
  max-height: 375px;
  overflow: auto;

  &Sections,
  &Services {
    padding-bottom: 15px; /* compensates for radio button padding */
  }
  &Times {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.summaryMsgContainer {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.popperContent {
  padding: 2px 5px;
  margin-top: 3px;
  &:first-child {
    margin-top: 0;
  }
}

.popperText {
  font-size: 0.9rem;
}

.loaderWrap {
  width: 100%;

  &IsCentered {
    display: flex;
    justify-content: center;
  }
}

.servicesAlert,
.sectionsAlert,
.coversAlert {
  margin-top: 12px;
}

