
.radWrap {
  display: block;
}

.radGrp {
  display: block;
}

.radBtn {
  padding: 3px 5px;
}

.radLbl {
  margin-right: 18px;
}

.validationMsg {
  margin-top: 15px;
}

.counterMessage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.msg {
  padding-right: 10px;
}
.box {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;

  .boxItems {
    padding: 8px;
    min-width: 25%;
    max-width: 25%;
    display: flex;
    flex-direction: column;
  }
}
