
.cvcPopover {
  padding: 10px 15px;
  width: 305px;
  background-color: #fff;

  &Img {
    width: 275px;
    height: 275px;
  }

  &Text {
    font-size: 1.1rem;

    &IsDark {
      color: rgba(0,0,0,0.87); /* forces it to use light theme always because image has white bg */
    }
  }
}

.cvcLink {
  position: absolute;
  right: 0;
  bottom: 6px;
  font-size: 1rem;
  padding: 3px 9px;
}