.root {
  padding: 0 0 15px;
  
  display: flex;
  &IsTightFit {
    display: block;
  }
}

.text {
  flex-grow: 1;

  &IsTightFit {
    width: 100%;
  }
}

.btnWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 0 3px 15px;

  &IsTightFit {
    padding-left: 0;
    margin-top: 12px;
  }
}