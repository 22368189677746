.root {
  padding: 15px;
}

.termsControl {
  margin-right: 25px;
  margin-bottom: -10px;
}

.checkbox {
  padding-right: 7px;
}