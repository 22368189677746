.root {
  padding: 3px 12px;
}

.link a {
  color: #fff;
}

.btn {
  max-width: 140px;
  margin-top: 10px;
}
