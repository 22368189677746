

.column2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.progressStepper {
  max-width: 700px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.agreeText {
  margin-top: 30px;
  line-height: 1.3;
}

/* can't unmount the stripe elements, so hide them */
.stripeWrap {
  &IsHidden {
    visibility: hidden;
    position: absolute;
    height: 0;
    width: 0;
  }
}

.detailsSummaryTable {
  margin-bottom: 15px;
}

$mainContainerWidth: 1024px;

.mainContainer {
  padding: 20px 10px 16px;
  width: 100%;
  min-width: 320px;
  max-width: $mainContainerWidth;
  margin: 0 auto;
}