

@mixin inputAutoFill {
  // overrides autofill background color on chrome
  &:-webkit-autofill {
    &, &:hover, &:focus, &:active {

      /*
       * Hack to prevent background color looking crappy. Transition delay is so long it "never" gets seen.
       * REF: https://stackoverflow.com/questions/29580704/change-input-background-to-transparent-on-autocomplete-form
       */
      transition-delay: 9999s;
    }
  }
}