.BrainhubCarouselItem {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &.BrainhubCarouselItem--clickable {
    cursor: pointer;
  }
  &.BrainhubCarouselItem--active {

  }
  .debug-number {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    font-size: 2em;
    text-shadow: 0px 0px 9px white;
  }
}
