/*@import '../../containers/App/scss/variables.scss'; */

$sessionBannerH: 20px;
$mainContainerWidth: 1024px;

.root {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: flex-start;
}

.appVersion {
  color: #ddd;
  position: absolute;
  right: 15px;

  &HasSessionText {
    right: 160px;
  }

  &IsStacked {
    bottom: -24px;
    right: 7px;
  }
}

.businessLabel {
  width: auto;
  font-size: 16px;
}

.logoWrap {

  &IsHidden {
    display: none;
  }

  img {
    display: block;
    max-width: 100%;
    max-height: 40px;
  }

  max-width: 47%;
  max-height: 40px;
  &IsLandscape {
    max-width: 350px;
    max-height: 52px;

    img {
      max-height: 52px;
    }
  }
  &IsStandard {
    max-width: 280px;
  }
}

.container {
  position: relative;
  max-width: $mainContainerWidth;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 10px;

  $stackedH: 58px;

  min-height: $stackedH;
  &IsLandscape {
    min-height: 70px;
  }
  &HasStackedBanner {
    padding-bottom: $sessionBannerH;
    min-height: $stackedH + $sessionBannerH;
  }

  &HasCenteredLogo {
    justify-content: center;
  }

  &IsHidden {
    min-height: 0;
  }
}

.title {
  line-height: 1.2;
  font-size: 1.7rem;
  text-align: right;

  &IsHidden {
    display: none;
  }

  &IsLandscape {
    line-height: 1.3;
    font-size: 1.6rem;
    text-align: left;
    &HasLogo {
      text-align: center;
    }
  }

  &IsStacked {
    &NoLogo {
      width: 100%;
      text-align: center;
    }
  }

  &IsForcedLight {
    color: white;
  }
}

.venueTitle {
  &IsForcedLight {
    color: white;
  }
}

/* .returnLink { */
/*   cursor: pointer; */
/* } */

.sessionRemaining {
  display: flex;
  border-radius: 6px;
  padding: 5px 6px;
  margin: 10px;

  &IsStacked {
    justify-content: center;
    align-items: center;
    height: $sessionBannerH;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 0;
    padding: 0;

    .hideBreak {
      display: none;
    }
  }

  &IsRounded {
    border-radius: 5px;
  }
}


.sessionRemainingText {
  font-size: 1.2rem;
  line-height: 1.3;
  text-align: center;
}

.rightSide {
  display: flex;
  align-items: center;

  &HasLogo {
    /* helps to balance out the logo */
    width: 20px;
    &IsLandscape {
      width: 40px;
    }
  }

  &HasSessionText {
    width: auto;
  }

  /* don't want it to affect parent flex rules on mobile/stacked view (but still want it visible for sessionRemaining child element */
  &IsStacked {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    width: auto;
  }

  &IsHidden {
    display: none;
  }
}

.businessName {
  &IsHidden {
    display: none;
  }
}

.logoutIcon{
  cursor: pointer;
}

.version{
  margin: 20px 10px 0 0;
}
